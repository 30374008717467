@import 'fonts';
@import 'colors';

@keyframes cursor-blink {
    to {
        visibility: hidden;
    }

}

@keyframes rotating {
    from {
    	transform: rotate(0deg);
    }
   	to {
    	transform: rotate(360deg);
	}
}

.pace {
    pointer-events: none;
    user-select: none;
    z-index: 2000;
    position: fixed;
    margin: auto;
    top: 64px;
    left: 15px;
    right: 15px;
    bottom: 0;
    height: 5px;
    max-width: 224px;
    background: $color-bg;
    border: 1px solid $color-primary;
    overflow: hidden;
    z-index: 100;
    cursor:
    	url('../img/cursors/wait_r.cur'),
    	none;

	&:before {
		content: '';
		width: 1px;
		height: 1px;
		float: left;
		position: absolute;
		top: 1px;
		left: 0;
		z-index: 2001;
		background: $color-bg;
	}

	&:after {
		content: '';
		width: 1px;
		height: 1px;
		float: left;
		position: absolute;
		top: 1px;
		right: 0;
		z-index: 2001;
		background: $color-bg;
	}

	&.pace-inactive {
    	display: none;
	}

	.pace-progress {
	    box-sizing: border-box;
	    transform: translate3d(0, 0, 0);
	    position: fixed;
	    z-index: 2000;
	    display: block;
	    position: absolute;
	    right: 100%;
	    height: 1px;
	    margin-top: 1px;
	    width: 100%;
	    background: $color-primary;
	}

}

.__pace-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $color-bg;
	z-index: 11;
	cursor:
    	url('../img/cursors/wait_r.cur'),
    	none;

    .__pace-overlay__pace-image {
    	width: 32px;
    	height: 32px;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50%);

    	img {
    		width: 100%;
    		display: block;

    	}
    }
}

::selection {
	background: $color-magenta;
	color: $color-bg;
	text-shadow: none;
}

body {
	color: $color-primary;
	background: $color-bg;
	font-size: 14px;
	line-height: 30px;
	font-weight: bold;
	font-family: 'Akkurat Pro', 'Helvetica Neue', Helvetica, Arial, 'Liberation Sans', sans-serif;
	letter-spacing: 0.1em;
	cursor:
		url('../img/cursors/arrow_r.cur'),
		url('img/cursors/arrow_r.cur'),
		auto;
	overflow: hidden;

	&:before {
		position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    	content: url('../img/cursors/wait_r.cur');
	}

	&.modal-open {
		overflow: hidden !important;
	}

	&.--char-cyr {
		font-family: 'Akzidenz-Grotesk Pro', 'Helvetica Neue', Helvetica, Arial, 'Liberation Sans', sans-serif;

		h2.case-studies__study-link__heading__title {
			font-family: 'Akzidenz-Grotesk Pro Condensed', 'Helvetica Neue', Helvetica, Arial, 'Liberation Sans', sans-serif !important;
			font-weight: bold !important;
		}

		article.__case-study {
			// letter-spacing: 0.035em !important;
			// font-weight: 500 !important;

			h3.__article-title {
				font-family: 'Akzidenz-Grotesk Pro Condensed', 'Helvetica Neue', Helvetica, Arial, 'Liberation Sans', sans-serif !important;
				// font-weight: bold !important;
			}
		}
	}

	.body__body-container {
		position: relative;
		overflow-x: hidden;
	}
}

a {
	color: $color-primary;
	text-decoration: underline;
	cursor:
		url('../img/cursors/link_select_inverted.cur'),
		url('img/cursors/link_select_inverted.cur'),
		auto;

	&:hover {
		color: $color-primary;
	}

	&:focus {
		color: $color-primary;
		outline: none;
	}
}

header.main-header {
	position: relative;
	margin-top: 190px;
	min-height: 488px;

	@media screen and (max-width: 919px) {
		min-height: 550px;
	}

	@media screen and (max-height: 768px) {
		margin-top: 98px;
	}

	@media screen and (max-width: 767px) {
		margin-top: 15px;
		min-height: 758px;
	}

	

	.container .row {
		position: relative;

		&:before {
			content: url('../img/body-cross.svg');
			position: absolute;
			right: -8px;
			top: -23px;


			@media screen and (max-width: 767px) {
				content: url('../img/body-xs-angle.svg');
				top: 0;
				right: 15px;
			}
		}
	}

	.main-header__lang-menu {
		mix-blend-mode: exclusion;
		margin-top: 90px;
		text-align: right;
		text-transform: uppercase;

		.__lang-menu__lang {
			text-decoration: none;
			margin-left: 24px;

			&:hover {
				background: $color-accent;
				color: $color-bg;
			}

			&.--current {
				
			}
		}

		@media screen and (max-width: 991px) {
			mix-blend-mode: normal;

			.__lang-menu__lang {
				background: $color-bg;
			}
		}

		@media screen and (max-width: 767px) {
			padding-top: 387px;
			margin-top: 0;
		}
	}

	.main-header__intro-text {
		z-index: 1;
		margin-bottom: 0;
		mix-blend-mode: exclusion;
		margin-top: 30px;

		.main-header__intro-text__span {
			position: absolute;
			visibility: hidden;
		}

		.main-header__intro-text__span-typeit {
			position: relative;
			text-align: justify;
			text-transform: lowercase;

			span {
				.ti-container {
					margin-right: -0.3em;
				}
			}

			&.--complete {
				&:after {
					animation: cursor-blink 1800ms steps(2, start) infinite;
				}
			}

			&:after {
				content: '█';
				display: inline-block;
				position: relative;
				margin-left: 0.1em;
			}
		}

		@media screen and (max-width: 991px) {
			mix-blend-mode: normal;

			span {
				background: $color-bg;
			}
		}

		@media screen and (max-width: 767px) {
			cursor:
				url('../img/cursors/arrow_r.cur'),
				url('img/cursors/arrow_r.cur'),
				auto;
			margin-top: 0;
		}
	}

	.main-header__logotype-full {
		position: absolute;
		top: 0;
		left: 15px;
		display: none;

		img.main-header__logotype-full__md {
			width: 1331px;
			height: 367px;
		}
	}

	.main-header__logomark-outline {
		position: absolute;
		top: 0;
		left: 15px;
		cursor: none;

		@media screen and (max-width: 767px) {
			z-index: 1;
		}

		img {
			width: 322px;
			height: 367px;
		}
	}

	.main-header__logomark-outline-spaz {
		position: absolute;
		top: 0;
		left: 15px;
		display: none;

		@media screen and (max-width: 767px) {
			z-index: 1;
		}

		img {
			width: 322px;
			height: 367px;
		}
	}

}

section.section {
	margin-top: 128px;

	@media screen and (max-width: 767px) {
		margin-top: 70px;
	}

	.section__heading {
		position: relative;

		&:before {
			content: "";
			display: block;
			height: 1px;
			background: $color-primary;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
		}


		h1.section__heading__title {
			font-size: 14px;
			font-weight: bold;
			text-transform: lowercase;
			margin: 0;
			background: $color-bg;
			display: inline-block;
			float: left;
			position: relative;
			padding-right: 12px;
		}

	}

	.section__content {
		
	}
}

section.section.case-studies {
	.section__heading {

	}

	.section__content {
		.case-studies__study-link {
			position: relative;
			margin-top: 100px;
			min-height: 550px;

			@media screen and (max-width: 991px) {
				margin-top: 100px;
			}

			@media screen and (max-width: 767px) {
				margin-top: 70px;
			}

			.case-studies__study-link__heading {
				top: 146px;
				position: absolute;
				width: 100%;
				z-index: 2;
				text-transform: lowercase;

				.case-studies__study-link__heading__title {
					font-family: 'DIN Next Condensed', 'Helvetica Neue', Helvetica, Arial, 'Liberation Sans', sans-serif;
					font-weight: 500;
					font-size: 72px;
					text-shadow: 5px -5px 0px $color-bg;
					letter-spacing: 0;
					position: relative;
					filter: contrast(20000%);

					&:hover {
						filter: unset;
					}

					&:hover:before {
						content: url('../img/case-study-item-cross.svg');
						position: absolute;
						top: -65px;	

						@media screen and (max-width: 767px) {
							content: none;
						}
					}

					a {
						text-decoration: none;
					}

					a:focus {
						color: $color-primary;
					}

					a:hover {
						color: $color-primary;
					}

					@media screen and (max-width: 767px) {
						span {
							background: $color-bg;
						}
					}

				}

				.case-studies__study-link__heading__description {
					margin-top: 67px;
					margin-bottom: 0;

					&.--hover {
						color: $color-accent;
						// text-shadow: none;
					}

					@media screen and (max-width: 767px) {
						span {
							background: $color-bg;
						}
					}
				}
			}

			.case-studies__study-link__image {
				position: absolute;
				z-index: -1;
				visibility: hidden;
				width: 720px;

				@media screen and (max-width: 1199px) {
					width: 540px;
				}

				@media screen and (max-width:  991px) {
					width: 480px;
				}

				@media screen and (max-width: 767px) {
					width: 100%;
					max-width: 100%;
				}

				img {
					width: 100%;
					height: auto;
				}
			}

			.case-studies__study-link__image-dithered {
				display: none;
				position: relative;
				width: 720px;

				@media screen and (max-width: 1199px) {
					width: 540px;
				}

				@media screen and (max-width:  991px) {
					width: 480px;
				}

				@media screen and (max-width: 767px) {
					width: 100%;
					max-width: 100%;
				}

				img {
					width: 100%;
					height: auto;
				}
			}

			.case-studies__study-link__video {
				position: relative;
				

				.dither-video-canvas-wrapper {

				}

				.source-video-canvas {
					display: none;
				}

				.dither-video-canvas {
					margin-bottom: -10px;
				}

				.source-video {
					max-height: 768px;
					max-width: 768px;
					visibility: hidden;
					position: absolute;
					z-index: -1;
					width: 720px;

					@media screen and (max-width: 1199px) {
						width: 540px;
					}

					@media screen and (max-width:  991px) {
						width: 480px;
					}

					@media screen and (max-width: 767px) {
						width: 100%;
						max-width: 100%;
					}
				}

				&.--hover {
					.dither-video-canvas-wrapper {
						border-color: $color-primary;

						.dither-video-canvas {
							filter: invert(100%) url('../img/filter-accent.svg#accent');
						}
					}
				}
			}

			&.--align-right {
				.case-studies__study-link__heading {
					.case-studies__study-link__heading__title {
						&:hover {
							text-shadow: 5px 5px 0px $color-accent;
						}


						&:hover:before {
							left: -45px;
							right: auto;
						}
					}

					.case-studies__study-link__heading__description {
						text-shadow: 1px 1px 0px $color-bg;
					}
										
				}

				.case-studies__study-link__video {
					.dither-video-canvas-wrapper {
						float: right;
					}

					&.--hover {
						.dither-video-canvas-wrapper {
							box-shadow: 15px -15px 0px 0px $color-primary;
							// transform: translate(-10px, -10px);
						}
					}
				}

				.case-studies__study-link__image-dithered {
					float: right;
				}
			}

			&.--align-left {
				.case-studies__study-link__heading {
					.case-studies__study-link__heading__title {
						text-align: right;

						&:hover {
							text-shadow: -5px 5px 0px $color-accent;
						}

						&:hover:before {
							right: -45px;
							left: auto;
						}
					}

					.case-studies__study-link__heading__description {
						text-align: right;
						text-shadow: -1px 1px 0px $color-bg;
					}
				}

				.case-studies__study-link__video {
					.dither-video-canvas-wrapper {
						float: left;
					}

					&.--hover {
						.dither-video-canvas-wrapper {
							box-shadow: -15px -15px 0px 0px $color-primary;
							// transform: translate(10px, 10px);
						}
					}
				}

				.case-studies__study-link__image-dithered {
					float: left;
				}
			}
		}
	}
}

section.section.partners {

	.section__content {
		text-align: center;
		margin-top: 62px;
		margin-bottom: 62px;

		.partners__partners-list {
			width: 100%;
			position: relative;

			.partners__partners-list__partner-container {
				display: block;
				width: 20%;
				float: left;

				@media screen and (max-width: 767px) {
					width: 33.333333%
				}


				.partners__partners-list__partner {
					display: inline-block;
					position: relative;
					width: 60%;
					height: 0;
    				padding-bottom: 60%;

    				img {
						width: 100%;
						padding: 40px;
						display: block;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}

    				@media screen and (max-width: 1199px) {
						img {
							padding: 30px;
						}
					}

					@media screen and (max-width: 991px) {
						img {
							padding: 25px;
						}

						width: 75%;
						padding-bottom: 75%;
					}

					@media screen and (max-width: 767px) {
						img {
							padding: 25px;
						}
					}

					.__partner-logo-bg {
						display: none;
					}

					&:hover, &:focus, &:active {
						background: $color-accent;

						img {
							padding: 5px;
						}

						.__partner-logo {
							display: none;
						}

						.__partner-logo-bg {
							display: block;
						}
					}

					

					
				}
			}


		}
	}


}

section.section.contact {
	margin-top: 0;

	.section__content {
		margin-top: 113px;

		@media screen and (max-width: 991px) {
			margin-top: 56px;
		}


		.contact__contact-form {
			color: $color-primary;
			position: relative;

			.contact_contact-form__input-group {
				position: relative;
			}



			label {
				color: $color-primary;
				position: absolute;
				z-index: -1;
				text-transform: lowercase;
				text-align: right;
				
				left: 0;
				line-height: 42px;
				letter-spacing: 0.1em;

				@media screen and (max-width: 991px) {
					left: 0;
					font-style: normal;
				}

				&.--non-empty {
					width: 150px;
					left: -166px;
					font-style: normal;

					@media screen and (max-width: 991px) {
						left: 0;
						width: auto;
					}
				}

				&.--focus {
					color: $color-accent;
				}
			}

			input[type="text"], input[type="email"] {
				position: realative;
				background: transparent;
				border: 1px solid $color-primary;
				border-right: none;
				border-left: none;
				border-top: none;
				width: 100%;
				outline: none;
				line-height: 42px;
				letter-spacing: 0.1em;
				padding: 0;
				height: 42px;
				margin-bottom: 56px;
				cursor:
					url('../img/cursors/beam_r.cur'),
					url('img/cursors/beam_r.cur'),
					text;

				&:not([value=""]):not(:focus) {
					background: $color-bg;
				}

				&:focus {
					border-color: $color-accent;
					background: $color-bg;

					&:hover {
						border-bottom-style: solid;
					}
				}

				&:hover {
					border-bottom-style: dotted;
				}

				@media screen and (max-width: 991px) {
					margin-top: 42px;
					margin-bottom: 32px;
				}
			}

			textarea {
				position: relative;
				background: transparent;
				border: 1px solid $color-primary;
				border-right: none;
				border-left: none;
				border-top: none;
				width: 100%;
				height: 156px;
				outline: none;
				padding: 0;
				padding-top: 8px;
				line-height: 24px;
				letter-spacing: 0.1em;
				cursor:
					url('../img/cursors/beam_r.cur'),
					url('img/cursors/beam_r.cur'),
					text;
				resize: none;

				&:hover {
					border-bottom-style: dotted;
				}

				@media screen and (max-width: 991px) {
					margin-top: 42px;
				}

				&:not([value=""]):not(:focus) {
					background: $color-bg;
				}

				&:focus {
					border-color: $color-accent;
					background: $color-bg;

					&:hover {
						border-bottom-style: solid;
					}
				}
			}

			button {
				padding: 0;
				margin: 0;
				background: transparent;
				border: none;
				text-transform: lowercase;
				letter-spacing: 0.1em;
				outline: none;
				cursor: 
					url('../img/cursors/link_select_inverted.cur'), 
					url('img/cursors/link_select_inverted.cur'),
					pointer;
				float: right;

				&:hover {
					span {
						background: $color-accent;
						color: $color-bg;
					}
				}
			}

			.contact_contact-form__message-status {
				position: relative;
				display: none;

				&.--success {
				}

				&.--failure {
					padding-left: 28px;

					&:before {
						content: url('../img/icon-warn.png');
						position: absolute;
						top: 2px;
						left: 0;
					}
				}
			}
		}

		.contact__contact-data {
			margin-top: 57px;

			.contact__contact-data__contact-data-item {
				margin-top: 56px;

				@media screen and (max-width: 767px) {
					margin-top: 24px;
					text-align: right;
				}

				span {
					text-transform: lowercase;

					a {
						text-decoration: none;

						&:hover {
							background: $color-accent;
							color: $color-bg;
						}
					}
				}
			}
		}
	}
}

section.case-study-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 8;
	background: $color-bg url('../img/case-study-background-01.png') repeat;
	display: none;

	.case-study-modal__case-study-frame-container {
		position: absolute;
		height: 100vh;
		width: 100%;
		top: 0px;
		bottom: 0px;
		margin-bottom: 100px;
	}

	.case-study-modal__case-study-frame {
		position: absolute;
		top: 40px;
		bottom: 36px;
		left: 5px;
		right: 15px;
		box-shadow: 15px 15px 0px 0px $color-bg;
		background: $color-bg;

		&.--case-study-modal-hide {
			filter: opacity(0.5) contrast(20000%) invert(100%);
			box-shadow: none;

			.case-study-modal__case-study-frame_content {
				.__case-study {
					filter: grayscale(100%) contrast(20000%);
				}
			}
		}

		@media screen and (max-width: 767px) {
			left: 15px;
			right: 25px;
			top: 30px;
			bottom: 10px;
			box-shadow: none;
		}

		@media screen and (max-height: 479px) {
			box-shadow: none;
			top: 30px;
			bottom: 10px;
		}

		.case-study-modal__case-study-frame__header {
			position: relative;
			width: 100%;
			height: 27px;
			background:
				url('../img/case-study-frame-titlebar-right.png') top right no-repeat,
				url('../img/case-study-frame-titlebar-left.png') top left no-repeat,
				url('../img/case-study-frame-titlebar-middle.png') repeat-x,
				$color-bg;

			.case-study-modal__case-study-frame__header__close-modal {
				position: absolute;
				display: block;
				width: 16px;
				height: 14px;
				top: 6px;
				right: 6px;
				background: url('../img/case-study-frame-titlebar-button-close.png') no-repeat;
				cursor:
					url('../img/cursors/arrow_r.cur'),
					url('img/cursors/arrow_r.cur'),
					default;

				&:active {
					background: url('../img/case-study-frame-titlebar-button-close-pressed.png') no-repeat;
				}
			}
		}

		.case-study-modal__case-study-frame_content {
			background:
				url('../img/case-study-frame-border-right-bottom.png') bottom right no-repeat,
				url('../img/case-study-frame-border-left-bottom.png') bottom left no-repeat,
				url('../img/case-study-frame-border-left-middle.png') left repeat-y,
				url('../img/case-study-frame-border-right-middle.png') right repeat-y,
				url('../img/case-study-frame-border-bottom-middle.png') bottom repeat-x;
			position: absolute;
			top: 27px;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 0 6px 6px 6px;
			overflow: hidden;
			font-weight: normal;

			.case-study-modal__case-study-frame_content__loader {
				position: absolute;
				top: 0;
				left: 6px;
				right: 6px;
				bottom: 6px;
				z-index: 2;
				cursor:
					url('../img/cursors/busy_r.cur'),
					url('img/cursors/busy_r.cur'),
					auto;

				.__loader__empty {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: $color-bg;
				}
			}

			article.__case-study {
				display: none;
				letter-spacing: 0;
				position: absolute;
				top: 0;
				left: 6px;
				bottom: 6px;
				right: 6px;
				overflow-x: hidden;
				overflow-y: auto;
				padding: 15px;
				background: $color-bg;

				&>*:last-child {
					margin-bottom: 147px;

					@media screen and (max-width: 767px) {
							margin-bottom: 0;
					}
				}

				&.--waiting {
					filter: grayscale(1) brightness(1.5) invert(100%) contrast(20000%);
				}

				h3.__article-title {
					font-family: 'DIN Next Condensed', 'Helvetica Neue', Helvetica, Arial, 'Liberation Sans', sans-serif;
					font-weight: normal;
					font-size: 64px;
					text-align: right;
					margin-bottom: 0;
					margin-top: 32px;
					line-height: 1;
				}

				p {
					text-align: justify;
					margin-top: 18px;
					margin-bottom: 0;

					a {
						display: inline-block;
						font-weight: bold;

						&:before {
							content: url('../img/case-study-inline-link.png');
							margin-right: 4px;
							position: relative;
						}
					}
				}

				p.__article-info {
					text-align: right;
					margin-top: 45px;
				}

				h4 {
					font-size: 18px;
					font-weight: bold;
					margin-top: 18px;
					margin-bottom: 0;
					line-height: 1.8;
				}

				blockquote {
					margin-top: 18px;
					border: none;
					font-size: 14px;
					text-align: justify;
					font-style: italic;
					padding-right: 0;
					padding-left: 48px;
					padding-top: 0;
					padding-bottom: 0;
					margin-bottom: 0;
				}

				._case-study-img-container {					
					margin-top: 88px;
					margin-bottom: 18px;

					._case-study-img-container__img {
						margin-left: -15px;
						margin-right: -16px;
						padding-left: 15px;
						background: $color-accent;

						@media screen and (max-width: 767px) {
							padding-left: 0;
						}

						img {
							max-width: 100%;
							max-height: 768px;
						}
					}

					._case-study-img-container__img-description {
						margin-top: 8px;

						span {
							font-style: italic;
						}
					}
				}

				.case-study-video-container {					
					margin-top: 88px;
					margin-bottom: 18px;

					.case-study-video-container__video {
						margin-left: -15px;
						margin-right: -16px;
						padding-left: 15px;
						background: $color-accent;
						position: relative;

						@media screen and (max-width: 767px) {
							padding-left: 0;
						}

						.__video-wrapper {
							position: relative;
							padding-bottom: 56.25%;
							height: 0;

							video {
								max-width: 100%;
								margin-bottom: -10px;
								width: 100%;
							}

							iframe {
								margin-bottom: -10px;
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								
							}
						}
					}

					.case-study-video-container__video-description {
						margin-top: 8px;

						span {
							font-style: italic;
						}
					}
				}

				.__case-study__carousel-slider {
					width: auto;
					margin-top: 88px;

					&.--top {
						margin-top: -15px;
						margin-bottom: 0;
					}

					.__case-study__carousel-slider__slide {
						position: relative;
						height: 70vh;
						cursor:
							url('../img/cursors/size3_r.cur'),
							url('img/cursors/size3_r.cur'),
							col-resize;
						margin-right: 14px;
						padding-right: 14px;
						margin-left: -15px;
						margin-bottom: 8px;
						background: $color-accent;

						&>.row>div {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							margin-right: -1px;
						}

						img {
						    max-height: 100%;  
						    max-width: 100%; 
						    width: auto;
						    height: auto;
						    position: absolute;  
						    top: 0;  
						    bottom: 0;  
						    left: 0;  
						    right: 0;  
						    margin-top: auto;
						    margin-bottom: 0;
						    padding-left: 15px;

						    @media screen and (max-width: 767px) {
								padding-left: 0;
							}
						}
					}

					.__case-study__carousel-slider__slide__description {
						visibility: hidden;
						min-height: 120px;

						@media screen and (max-width: 767px) {
							min-height: 190px;
						}

						&.--visible {
							visibility: visible;
						}

						span {
							font-style: italic;
						}						
					}

					.owl-dots {
					    counter-reset: dots;
					    text-align: right;

					    @media screen and (max-width: 991px) {
					    	text-align: left;
					    } 
					}

					.owl-dot {
						display: inline-block;
						text-align: center;
						margin-left: 8px;

						@media screen and (max-width: 991px) {
					    	margin-left: 0;
					    	margin-right: 8px;
					    } 

						&:before {
						    counter-increment: dots;
						    content: counter(dots);
						    
						}

						&:hover {
							&:before {
								text-decoration: underline;
							}
						}

						&.active {
							&:before {
								content: "•";
							}
						}
					}
					
					.owl-stage-outer {
						
					}

					.owl-stage {

					}

					
				}
			}
		}
	}
}

footer.footer {
	margin-top: 113px;
	padding-bottom: 128px;

	@media screen and (max-width: 767px) {
		margin-top: 84px;
		padding-bottom: 10px;
	}


	.footer__unno {
		font-family: Arial, sans-serif;
		font-weight: regular;
		font-size: 16px;
		text-align: right;
		span {
			display: inline-block;
			cursor:
				url('../img/cursors/hns.cur'),
				url('img/cursors/hns.cur'),
				none;
			
			&:hover {
				animation: rotating 1s linear infinite;
			}
		}
	}
}

section.browser-redirect {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 8;
	background: $color-bg url('../img/case-study-background-01.png') repeat;

	a {
		font-weight: bold;
		margin-left: 4px;

		&:before {
			content: url('../img/case-study-inline-link.png');
			margin-right: 4px;
			position: relative;
		}
	}

	.browser-redirect__browser-redirect-frame-container {
		position: absolute;
		height: 100vh;
		width: 100%;
		top: 0px;
		bottom: 0px;
		margin-bottom: 100px;

		.browser-redirect__browser-redirect-frame {
			position: absolute;
			top: 40px;
			bottom: 36px;
			left: 5px;
			right: 15px;
			box-shadow: 15px 15px 0px 0px $color-bg;
			background: $color-bg;

			@media screen and (max-width: 767px) {
				left: 15px;
				right: 25px;
				top: 30px;
				bottom: 10px;
				box-shadow: none;
			}

			@media screen and (max-height: 479px) {
				box-shadow: none;
				top: 30px;
				bottom: 10px;
			}

			.browser-redirect__browser-redirect-frame__header {
				position: relative;
				width: 100%;
				height: 27px;
				background:
				url('../img/case-study-frame-titlebar-right.png') top right no-repeat,
				url('../img/case-study-frame-titlebar-left.png') top left no-repeat,
				url('../img/case-study-frame-titlebar-middle.png') repeat-x,
				$color-bg;
			}

			.browser-redirect__browser-redirect-frame__content {
				background:
					url('../img/case-study-frame-border-right-bottom.png') bottom right no-repeat,
					url('../img/case-study-frame-border-left-bottom.png') bottom left no-repeat,
					url('../img/case-study-frame-border-left-middle.png') left repeat-y,
					url('../img/case-study-frame-border-right-middle.png') right repeat-y,
					url('../img/case-study-frame-border-bottom-middle.png') bottom repeat-x;
				position: absolute;
				top: 27px;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 0 6px 6px 6px;
				overflow: hidden;
				font-weight: normal;

				.browser-redirect__browser-redirect-frame__content-wrapper {
					position: absolute;
					top: 0;
					left: 6px;
					bottom: 6px;
					right: 6px;
					overflow-x: hidden;
					overflow-y: auto;
					padding: 15px;
					background: $color-bg;
					letter-spacing: 0;
					padding-top: 72px;
					text-align: justify;

					ul {
						// list-style-type: none;
					}

					@media screen and (max-width: 767px) {
						padding-top: 15px;
					}
				}



			}
		}
	}

}




