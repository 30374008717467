@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Bold.eot');
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Bold.woff') format('woff'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Light.eot');
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Light.woff') format('woff'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Italic.eot');
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Italic.woff') format('woff'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-LightItalic.eot');
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-LightItalic.woff') format('woff'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Regular.eot');
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Regular.woff') format('woff'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-BoldItalic.eot');
    src: url('../fonts/font-Akkurat-Pro/AkkuratPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-BoldItalic.woff') format('woff'),
    url('../fonts/font-Akkurat-Pro/AkkuratPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Md.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Md.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Md.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Md.ttf') format('truetype');
    font-weight: 500;
	font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightIt.ttf') format('truetype');
    font-weight: 200;
	font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-SuperItalic.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-SuperItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-SuperItalic.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-SuperItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Bold.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Bold.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Regular.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Regular.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBd.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBd.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBd.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBd.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-It.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-It.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-It.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Super.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Super.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Super.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Super.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Light.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Light.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCn.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCn.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCn.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCn.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-CnIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-CnIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-CnIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-CnIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCnIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCnIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCnIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCnIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCn.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCn.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCn.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-MdCn.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCnIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCnIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCnIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-XBdCnIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCn.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCn.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCn.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCn.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCnItalic.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCnItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCnItalic.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-LightCnItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Cn.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Cn.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Cn.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-Cn.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCn.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCn.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCn.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCn.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk Pro Condensed';
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCnIt.eot');
    src: url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCnIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCnIt.woff') format('woff'),
    url('../fonts/font-Akzidenz-Grotesk-Pro/AkzidenzGroteskPro-BoldCnIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-UltraLight.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-UltraLight.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-UltraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightIt.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightIt.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightIt.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Light.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Light.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-LightItalic.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-LightItalic.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Regular.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Regular.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Italic.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Italic.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Medium.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Medium.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-MediumItalic.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-MediumItalic.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Bold.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Bold.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BoldItalic.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BoldItalic.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Heavy.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Heavy.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-HeavyItalic.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-HeavyItalic.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-HeavyItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Black.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Black.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


@font-face {
    font-family: 'DIN Next';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BlackItalic.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BlackItalic.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next Condensed';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightCond.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightCond.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightCond.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-UltraLightCond.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Condensed';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-LightCondensed.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-LightCondensed.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-LightCondensed.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-LightCondensed.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Condensed';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Condensed.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-Condensed.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Condensed.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-Condensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Condensed';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-MediumCond.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-MediumCond.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-MediumCond.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-MediumCond.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Condensed';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BoldCondensed.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BoldCondensed.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BoldCondensed.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BoldCondensed.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Condensed';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BlackCondensed.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-BlackCondensed.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BlackCondensed.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-BlackCondensed.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Condensed';
    src: url('../fonts/font-DIN-Next/DINNextLTPro-HeavyCondensed.eot');
    src: url('../fonts/font-DIN-Next/DINNextLTPro-HeavyCondensed.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-DIN-Next/DINNextLTPro-HeavyCondensed.woff') format('woff'),
    url('../fonts/font-DIN-Next/DINNextLTPro-HeavyCondensed.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}